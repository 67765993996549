var $ = require("jquery");
var breakpoint = require('./app-breakpoints');
require('./app-accordion');


require('./app-map');


var slick = require("slick");
var _ = require('underscore');
var _gridSize = breakpoint().match(/(\d+)/g);
var _winSize = $(window).width();
var	_gridLabel = breakpoint().replace(/\d+/g, '').replace(" ", "").replace(/\"/g, "");

//var sticky = $('.c-navigation').offset().top;

require('./app-map');

$(function() {

    var iOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent);

    if(iOS){
        $('body').addClass('is-ios');
    }

    $('.c-slider .items').slick({
        autoplay: true,
        responsive: false,
        fade: true,
        dots: false,
        autoplaySpeed: 3000,
        arrows: true,
        speed: 500,
        cssEase: 'linear',
        centerMode: true,
        adaptiveHeight: true
		/*responsive: [
		 {
		 breakpoint: 1479,
		 settings: {
		 adaptiveHeight: true,
		 }
		 }

		 ]*/
    });

    $('.u-gal').slick();

    $('*[data-url]').on({
        click: function () {
            path = $(this).attr('data-url');
            document.location.href = path;
        }
    });


    $(document).on('click', '.accordeon', function(e) {
        $(this).toggleClass('active');
        $(this).next('ul').slideToggle();

    });
    $(document).on('click', '.nav-trigger', function(e) {
		$(this).toggleClass('is-active');
		//$('#menu-mobile').slideToggle();
		$('body, .menu-bg, .l-mobile').toggleClass('fs');
		$('body, .menu-bg, .l-mobile .menu-bg-img').toggleClass('wow');
		return false;
	});




    $(".form-field input").each(function() {
        if($(this).val() != ""){
            $(this).parent().addClass("has-content");
        }else{
            $(this).parent().removeClass("has-content");
        }

    });

    $(".form-field input").focusout(function(){
        if($(this).val() != ""){
            $(this).parent().addClass("has-content");
        }else{
            $(this).parent().removeClass("has-content");
        }
    });
    $(".form-field select").change(function(){
        if($(this).val() != 0){
            $(this).parent().addClass("has-content");
        }else{
            $(this).parent().removeClass("has-content");
        }
    });

    $(".form-field textarea").focusout(function(){
        if($(this).val() != 0){
            $(this).parent().addClass("has-content");
        }else{
            $(this).parent().removeClass("has-content");
        }
    });
    $(".form-field textarea").change(function(){
        console.log($(this).val());
        if($(this).val() != 0){
            $(this).parent().addClass("has-content");
        }else{
            $(this).parent().removeClass("has-content");
        }
    });

    if($('.filter__list').length!=0){


        $('.filter__list li').click(function(e){
            //$('.filter__list li').removeClass('open');

            if ($(this).hasClass('open')) {
                $('.filter__list li').removeClass('open');
                //$(this).removeClass('open');

            }else{
                $('.filter__list li').removeClass('open');
                $(this).addClass('open');
            }

            $('body').click(function(e){
                var container = $('.filter__list li');
                if (!container.is(e.target) // if the target of the click isn't the container...
                    && container.has(e.target).length === 0) // ... nor a descendant of the container
                {
                    $('.filter__list li').removeClass('open');
                }
            });


        });

        $(document).on( "click",'.filter', function(e) {

            $type = $(this).data('type');
            $value = $(this).data('value');
            this$ = $(this);

            $(".filter__type:checked").each(function(index){
                if ($(this).val() == $value && $(this).attr('name') == $type) {
                    this$.fadeOut();
                    $(this).attr('checked', false);
                    $(this).trigger('change');
                }
            });

        });
        if($(".filter__type:checked").length > 0){
            ajax_filter();
        }

        $('.filter__type').change(function(){
            // Get tag slug from title attirbute
            ajax_filter();
        });
    }

});

function ajax_filter(){
    var selecetd_filter = {};
    var search = '';
    $('.filters__used').empty();
    $(".filter__type:checked").each(function(index){

        if (!($(this).attr('name') in selecetd_filter)) {
            selecetd_filter[$(this).attr('name')] = [];
        }


        if(!$(this).hasClass('hidden')) $('.filters__used').append($('<div class="filter" data-type="'+$(this).attr('name')+'" data-value="'+$(this).attr('value')+'" >'+$(this).next('label').text()+'</div>'));
        selecetd_filter[$(this).attr('name')].push($(this).attr('value'));

    });

    $('.units__wrapper').css({opacity:'0.5'});

    data = {
        action: 'filter_posts', // function to execute
        afp_nonce: afp_vars.afp_nonce, // wp_nonce
        filters: selecetd_filter, // selected tag
        //post_id: $('.c-half-right').attr('data-url'),
        icl_current_language: $('#icl_current_language').val(),
        //search: search, // selected tag
    };

    $.post( afp_vars.afp_ajax_url, data, function(response) {
        if( response ) {
            // Display posts on page
            $('.units').html( response );
            $('.count .nbr').text(($('.unit').length));

            if ($('.unit').length == 0) {
                $('#units_list').append($('<div class="empty-search"><h2>Aucun modèle disponible</h2></div>'));
            }
            // Restore div visibility
            $('.units').fadeIn();
        };
    });
}