var map = null;
window.initializeMap = function() {
    //var styles = mapStyle;
    var styles = [
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#f2f2f2"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46bcec"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            }
        ]
    ;
    //var t_latLng = [];
    //if ( $('#'+field_id + '_region').val()) {
    //adr = jQuery.trim(adr);
    //geocoder.geocode( { 'address': adr}, function(results, status) {
    t_latLng = $("#google-maps").attr("data-latlng").split(",");
    var latLng = new google.maps.LatLng(t_latLng[0],t_latLng[1]);
    var styledMap = new google.maps.StyledMapType(styles, {name: "Vilain Cabot"});

    var marker_pin = $("#google-maps").attr("data-pin");
    var options = {
        zoom      : 14,
        center    : latLng,
        disableDefaultUI: true,
        scrollwheel: false,
        mapTypeId : google.maps.MapTypeId.TERRAIN, // Type de carte, différentes valeurs possible HYBRID, ROADMAP, SATELLITE, TERRAIN
        maxZoom   : 20,
        mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
        },
        zoomControl: true
    };

    map  = new google.maps.Map(document.getElementById('google-maps'), options);
    map.mapTypes.set('map_style', styledMap);
    map.setMapTypeId('map_style');


    infowindow = new google.maps.InfoWindow({
        content: "Chargement..."
    });
    var bounds = new google.maps.LatLngBounds();

    $("#google-maps").each (function () {
        console.log($(this));

        //var address = $(this);

        var t_latLng = $(this).attr("data-latlng").split(",")
        var latlng = new google.maps.LatLng(t_latLng[0],t_latLng[1]);
        //console.log(t_latLng);
        //address.children('.google_maps_link').attr('href', "http://maps.google.com/maps?q=" + encodeURIComponent( $(this).data('address') ) + "");
        //address.children('.google_maps_link').attr('target', '_blank');
        var address = $(this).html();


        var marker = new google.maps.Marker({
            map: map,
            position: latlng,
            url: "https://www.google.com/maps/dir/Current+Location/"+t_latLng[0]+","+t_latLng[1]+"",
            icon: new google.maps.MarkerImage(marker_pin)
        });

        //extend the bounds to include each marker's position
        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', function() {
            window.open(this.url, '_blank');
            //window.location.href = this.url;
        });
    });
    //map.fitBounds(bounds);

    if ( $("#google-maps").length>1) map.fitBounds(bounds);
    /*var marker = new google.maps.Marker({
     map: map,
     position: latLng,
     icon: new google.maps.MarkerImage(marker_pin)
     });


     address_store*/
    /*var markerHtml = $('#address_store').html();

     google.maps.event.addListener(marker, "click", function() {

     if (infowindow) infowindow.close();
     infowindow = new google.maps.InfoWindow({
     content: markerHtml
     });
     infowindow.open(map, marker);

     });*/

}
if($("#google-maps").length > 0) {
    function loadScript() {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDLSGsYBL2SmdcuCf_1jhf4f5oGCMZWlVk&v=3.exp' +
            '&callback=initializeMap';
        document.body.appendChild(script);

    }


    window.onload = loadScript;
}